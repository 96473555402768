import { useEffect, useState, ChangeEvent, memo, useMemo, useCallback } from 'react';

import clsx from 'clsx';

import { Button } from 'primereact/button';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import {
	closestCenter,
	DndContext,
	DragOverlay,
	KeyboardSensor,
	useSensor,
	useSensors,
	type DragStartEvent,
	type Modifier,
	type DragEndEvent,
} from '@dnd-kit/core';
import {
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy
} from '@dnd-kit/sortable';

import { eventBus } from 'server/EventBus';
import { moveItem, replaceItemAt } from 'helpers/Utils/collections';

import { useSaveGridConfig } from 'components/OBXUser/Services/ProfileHooks';
import { GridConfiguration, GridColumn } from 'components/OBXUser/Model/ProfileResult';

import { GridConfigPanelEvents } from './Enums';
import SortableItem from './SortableItem';
import CustomPointerSensor from './CustomPointerSensor';

import styles from './GridColumnConfiguration.module.scss';

const DRAGGED_ITEM_OFFSET_X = 76;
const DRAGGED_ITEM_OFFSET_Y = 56;

type ComponentParams<T> = {
	allColumns?: GridColumn[],
	config: GridConfiguration<T>;
	setConfig: (c: GridConfiguration<T>) => void;
	icon: string,
	heading: string,
	description?: JSX.Element | string;
	searchFilter: boolean;
	propkey: string;
	children?: JSX.Element | undefined;
}

export const GridColumnConfiguration = memo((params: ComponentParams<any>): JSX.Element =>  {

	const { heading, icon, description, config, setConfig, searchFilter, propkey } = params;

	const sensors = useSensors(
		useSensor(CustomPointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const initAllColumns = (cols: GridColumn[]): GridColumn[] => {
		return [...cols.map(c => {
			const prop: string = c[propkey as keyof GridColumn] as string;
			return {...c, checked: config.columns.includes(prop)};
		})]
	}
	const { update: saveConfig } = useSaveGridConfig();

	const [ openState, setOpenState ] = useState<boolean>(false);
	const [ allColumns, setAllColumns ] = useState<GridColumn[]>(initAllColumns(params.allColumns ?? []));
	const [ filteredColumns, setFilteredColumns ] = useState<GridColumn[]>(allColumns);
	const [draggedItem, setDraggedItem] = useState<GridColumn | null>(null);
	const [isDragging, setIsDragging] = useState<boolean>(false);

	const onColumnOrderChanged = useCallback((event: CustomEvent<GridColumn[]>): void => {
		const { additional, grid } = config;

		const updated = {
			additional: {
				...additional,
				// save order so it can be restored on page entry, products array order might differ after item drag&drop
				order: event.detail?.map((c, i) => ({ id: c[propkey as keyof GridColumn], index: i }))
			},
			// Initiate columns means mainly 'add `checked` property'.
			// It's required because there is no such prop when moving active columns from table.
			columns: initAllColumns(event.detail).filter(c => c.checked).map(d => d[propkey as keyof GridColumn]) as string[],
			grid
		};

		setConfig(updated);
		saveConfig(updated);
		// eslint-disable-next-line
	}, [config, propkey]);

	useEffect(() => {
		eventBus.on(GridConfigPanelEvents.COLUMNS_ORDER_CHANGED, onColumnOrderChanged);
		eventBus.on(GridConfigPanelEvents.PANEL_VISIBILITY_CHANGE, onToggleVisibility);

		return () => {
			eventBus.remove(GridConfigPanelEvents.COLUMNS_ORDER_CHANGED, onColumnOrderChanged);
			eventBus.remove(GridConfigPanelEvents.PANEL_VISIBILITY_CHANGE, onToggleVisibility)
		};
	}, [onColumnOrderChanged]);

	useEffect(() => {
		if (!params.allColumns) return;

		setAllColumns(initAllColumns(params.allColumns))
		// eslint-disable-next-line
	}, [params.allColumns])


	const onToggleVisibility = () => setOpenState(curr => !curr);

	const handleDragStart = (event: DragStartEvent): void => {
		setIsDragging(true);
		setDraggedItem(filteredColumns.find(c => c.id === event.active.id) ?? null);
	};

	const handleDragEnd = (event: DragEndEvent): void => {
		const { active, over } = event;

		if (active && over && active.id !== over.id) {
			setFilteredColumns((items) => {
				const oldIndex = items.findIndex(i => i.id === active.id);
				const newIndex = items.findIndex(i => i.id === over.id);

				const newColumns = moveItem(items, oldIndex, newIndex, { placeBefore: false });

				eventBus.dispatch(GridConfigPanelEvents.COLUMNS_ORDER_CHANGED, newColumns);

				return newColumns;
			});
		}

		setIsDragging(false);
		setDraggedItem(null);
	}

	const onChange = (e: CheckboxChangeEvent) => {

		if (!config) return;

		const { checked } = e;
		const { additional, grid } = config;

		const allColumnIndex = allColumns.findIndex(f => f[propkey as keyof GridColumn] === e.value);

		const newColumns = replaceItemAt<GridColumn>(allColumns, {...allColumns[allColumnIndex], checked}, allColumnIndex)

		setAllColumns(newColumns);

		/** Now save the selection change to the users profile */
		const updated = {
			grid, additional,
			columns: newColumns.filter(c => c.checked).map(d => d[propkey as keyof GridColumn]) as string[]
		}

		saveConfig(updated);
		setConfig(updated);
	}

	const handleFiltering = (e: ChangeEvent<HTMLInputElement>) => {
		const searchstring = e.target.value.trim();

		setFilteredColumns(searchstring.length >= 2
			? allColumns.filter(c => new RegExp(`${searchstring}`, 'gi').test(c.label) || c.disabled)
			: allColumns
		)
	}

	useEffect(() => { setFilteredColumns(allColumns); }, [allColumns]);

	const sortableItems = useMemo(() => filteredColumns.map(c => c.name), [filteredColumns]);
	const draggedItemModifiers = useMemo(() => [(arg) => ({
		...arg.transform,
		x: arg.transform.x - DRAGGED_ITEM_OFFSET_X, // center item vertically
		y: arg.transform.y - DRAGGED_ITEM_OFFSET_Y, // center item horizontally
	})] as Modifier[], []);


  return <>
			<div className={clsx(
				styles.panel,
				openState && styles.open,
			)}>
				<header className={clsx(
					styles.header,
					icon && `${icon} icon--medium`
				)}>
					{ heading }
			  <Button
				  text
						size='small'
						icon='iconoir-xmark icon--small'
				  className={styles.close}
						onClick={() => setOpenState(false)}
					/>
				</header>
				{ description }
		  {searchFilter &&
					<div className="p-input-icon-left">
						<i className="icon--tiny iconoir-search" />
				  <InputText
							onChange={handleFiltering}
							placeholder='Filter by name'
						/>
					</div>
				}
		  <div className={clsx(styles.scroll, styles.dragging)}>
			  <DndContext
				  sensors={sensors}
				  collisionDetection={closestCenter}
				  onDragStart={handleDragStart}
				  onDragEnd={handleDragEnd}
			  >
				  <SortableContext
					  items={sortableItems}
					  strategy={verticalListSortingStrategy}
				  >
					  <ul>{
						  filteredColumns.map((c) => {
							  return <SortableItem
								  active={draggedItem?.id === c.id}
								  isDragging={isDragging}
								  item={c}
								  key={c.name}
								  onChange={onChange}
								  propkey={propkey}
							  />;
						  })
					  }
					  </ul>
					  <DragOverlay modifiers={draggedItemModifiers}>
						  {draggedItem ?
							  <SortableItem
								  isDragging={isDragging}
								  item={draggedItem}
								  placeholder
								  propkey={propkey}
							  /> :
							  null}
					  </DragOverlay>
				  </SortableContext>
			  </DndContext>
		  </div>
	  </div>
	</>;

});