import { Dispatch, SetStateAction, useCallback } from 'react';
import * as React from 'react';
import { Button } from 'primereact/button';

import { DLMessagesStatus } from '../../Models/Enums';
import { DistListApiService } from '../../Services/DistListService';

import { replaceItemAt } from 'helpers/Utils/collections';

import type {
  DistributionListMessagesSearchResponse,
  DistributionListMessagesSearchResponseFlat
} from '../../Models/distribution-list-response';

interface ResendButtonProps {
  data: DistributionListMessagesSearchResponseFlat;
  setMessages: Dispatch<SetStateAction<DistributionListMessagesSearchResponse[] | undefined>>;
  messages?: DistributionListMessagesSearchResponse[];
}

const ResendButton = (props: ResendButtonProps): JSX.Element => {
  const { data, setMessages, messages } = props;

  const resendMessage = useCallback((data: DistributionListMessagesSearchResponseFlat): void => {
    DistListApiService.Resend({
      emailId: data.id,
      requestingUserAddress: data.requestingUserEmailAddress,
      recipientAddress: data.emailAddress,
    });

    const index: number | undefined = messages?.findIndex(i => i.id === data.id);
    if (index !== -1 && index !== undefined && messages !== undefined) {
      const updatedRecipients = replaceItemAt(messages[index].recipients!,
        {...messages[index].recipients![data.recipientIndex], status: DLMessagesStatus.processed}, data.recipientIndex);
      const updatedMessages = replaceItemAt(messages,
        {...data, recipients: updatedRecipients}, index);
      setMessages(updatedMessages);
    }
  }, [messages, setMessages]);

  return (
    <Button
      size="small"
      text
      className="dl-resend no-background"
      onClick={():void => resendMessage(data)}
      disabled={[DLMessagesStatus.dropped].includes(data.status ?? DLMessagesStatus.null)}
    >
      Resend
    </Button>);
};

export default ResendButton;