import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';

import { ToastMessageRef } from 'components/ToastMessage';

import { deferNextAction } from '../../../CargoTracker/Components/CargoEditWarningDialog';
import { DistributionListResponse } from '../../Models/distribution-list-response';
import { DistListWarningDialogEvents } from '../CloseWarningDialog';
import ListEdit from '../ListEdit';
import Message from '../Message';

import './SidePanel.scss';

interface SidePanelProps {
  activeDetailTab: number;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  activeDL?: DistributionListResponse | null;
  handleClose: () => void;
  toast: RefObject<ToastMessageRef>;
}

const SidePanel = (props: SidePanelProps): JSX.Element => {
  const { activeDL, handleClose, toast, activeDetailTab, setActiveDetailTab } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const gestures = useSwipeable({
    onSwipedRight: ():void => {
      handleCloseSidePanel();
    },
  });

  const { emailIdParam } = useParams();

  useEffect(() => {
    if (emailIdParam) {
      setActiveDetailTab(0);
    }
  }, [emailIdParam, setActiveDetailTab]);

  const handleCloseSidePanel = ():void => {
    setActiveDetailTab(0);
    handleClose();
  };

  const handleTabChange = async (e: TabViewTabChangeEvent): Promise<void> => {
    const result = await deferNextAction(DistListWarningDialogEvents.ACTION,
      null,
      () => setActiveDetailTab(e.index));
    if (result) {
      return;
    }

    setActiveDetailTab(e.index);
  };

  return (
    <aside className="distlist-side-panel position--relative" {...gestures}>
      {isMobile ?
        (activeDetailTab === 1 ?
          <ListEdit
            activeDlId={activeDL?.id}
            handleClose={handleCloseSidePanel}
            toast={toast}
            activeDetailTab={activeDetailTab}
            setActiveDetailTab={setActiveDetailTab}
          /> :
          <Message
            activeDlId={activeDL?.id}
            handleClose={handleCloseSidePanel}
            toast={toast}
            dlName={activeDL?.name}
            activeDetailTab={activeDetailTab}
            setActiveDetailTab={setActiveDetailTab}
          />) :
        <TabView
          renderActiveOnly={true}
          activeIndex={activeDetailTab}
          onTabChange={handleTabChange}
          className="distlist-side-panel-tabs"
        >
          <TabPanel header="Message" disabled={!activeDL?.id}>
            <Message
              activeDlId={activeDL?.id}
              handleClose={handleCloseSidePanel}
              toast={toast}
              setActiveDetailTab={setActiveDetailTab}
            />
          </TabPanel>
          <TabPanel header="Recipients" contentClassName='direction--column'>
            <ListEdit
              activeDlId={activeDL?.id}
              handleClose={handleCloseSidePanel}
              toast={toast}
              setActiveDetailTab={setActiveDetailTab}
            />
          </TabPanel>
        </TabView>
      }
    </aside>
  );
};

export default SidePanel;