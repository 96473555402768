import { RefObject, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'primereact/hooks';
import { useMediaQuery } from 'react-responsive';

import Autocomplete from 'components/Autocomplete';
import { EntitySearchFieldsEnum, EntitySearchGroupEnum } from 'components/EntitySearch/Models/Enums';
import { SEARCH_DEBOUNCE_DELAY, type SearchSuggestionsGroupedResponse, type SearchSuggestionsResponse } from 'components/EntitySearch/Models/SearchEntities';
import { useSearchSuggestions } from 'components/EntitySearch/Services/SearchEntitiesAPI';
import { BlotterEntitySearchFieldsEnum, Section } from 'modules/Blotter/Models/Enums';
import { camelToSpace, checkIfNumberOrRange } from 'helpers/Utils/string';

import type { PriceSearchRequest, QuantitySearchRequest, SearchRequest, SearchRequestFields } from '../../Models/SearchRequest';
import type { SuggestionResponse } from 'modules/CargoTracker/Components/GroupedSearch';
import type { Suggestion, SuggestionItem } from 'components/Autocomplete';
import type { WorksheetMetaProps } from 'components/Worksheets/Models/WorksheetResponse';

interface BlotterSearchBarEntityProps {
  callback: (data: SearchRequestFields[]) => void;
  handleExternalItemsChangeCallback: (items: SearchRequestFields[]) => void;
  searchItems: SearchRequest;
  searchContainerRef: RefObject<HTMLElement>;
}

type SuggestionItemBlottter = Omit<SuggestionItem, 'searchField'> & { from?: number; to?: number; searchField?: BlotterEntitySearchFieldsEnum | EntitySearchFieldsEnum; };
type SuggestionBlotter = Omit<Suggestion, 'items'> & { items: SuggestionItemBlottter[]; };

export default function BlotterSearchEntity(param: BlotterSearchBarEntityProps): JSX.Element {
  const {
    callback,
    searchItems,
    searchContainerRef,
    handleExternalItemsChangeCallback
  } = param;
  const [suggestions, setSuggestions] = useState<SuggestionBlotter[]>([]);
  const [/* value */, debouncedValue, setValue] = useDebounce<string>('', SEARCH_DEBOUNCE_DELAY);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const { data, /* error,*/ isLoading, isValidating } = useSearchSuggestions<SearchSuggestionsResponse, SearchSuggestionsGroupedResponse[]>({
    module: EntitySearchGroupEnum.Blotter,
    fields: [
      EntitySearchFieldsEnum.Instrument,
      EntitySearchFieldsEnum.BuyerCompany,
      EntitySearchFieldsEnum.BuyerContactName,
      EntitySearchFieldsEnum.BuyerTradingAccount,
      EntitySearchFieldsEnum.SellerCompany,
      EntitySearchFieldsEnum.SellerContactName,
      EntitySearchFieldsEnum.SellerTradingAccount,
    ],
    term: debouncedValue,
    chunkSize: 10
  });

  const handleSelectedItemsChange = (items: SuggestionItemBlottter[], removedItem?: SuggestionItemBlottter): void => {
    const internal: SearchRequestFields[] = [];
    const external: (PriceSearchRequest | QuantitySearchRequest)[] = [];

    const newItems = Object.values(items).reduce((acc, curr) => {
      const metaData: WorksheetMetaProps[] = [];

      if (curr.external) {
        acc.external = [
          ...acc.external, {
            searchTerm: curr.searchTerm ?? '',
            searchField: curr.searchField ?? BlotterEntitySearchFieldsEnum.Price,
            metaData,
            from: curr.from,
            to: curr.to
          }
        ];
        return acc;
      }

      acc.internal = [
        ...acc.internal, {
          searchTerm: curr.searchTerm ?? '',
          searchField: curr.searchField ?? EntitySearchFieldsEnum.Instrument,
          metaData
        }
      ];
      return acc;

    }, { internal, external });

    if (!removedItem) {
      callback(newItems.internal);
      handleExternalItemsChangeCallback(newItems.external);
    }
  };

  const getGroupLabel = (searchField: EntitySearchFieldsEnum): string => {
    const field = EntitySearchFieldsEnum[searchField];
    let groupLabel: string = field;

    switch (searchField) {
      case EntitySearchFieldsEnum.BuyerCompany:
      case EntitySearchFieldsEnum.BuyerContactName:
      case EntitySearchFieldsEnum.BuyerTradingAccount:
        groupLabel = field.replace('Buyer', '');
        break;
      case EntitySearchFieldsEnum.SellerCompany:
      case EntitySearchFieldsEnum.SellerContactName:
      case EntitySearchFieldsEnum.SellerTradingAccount:
        groupLabel = field.replace('Seller', '');
        break;
    }

    return camelToSpace(groupLabel);
  }

  const suggestionMapper = (group: string): (item: SuggestionResponse, index: number, arr: SuggestionResponse[]) => SuggestionItem =>
    (item: SuggestionResponse, index: number, arr: SuggestionResponse[]): SuggestionItem => ({
      ...item,
      group,
      // set group label only for first of type item
      groupLabel: item.searchField !== arr[index - 1]?.searchField ? getGroupLabel(item.searchField) : ' ',
      label: item.value,
      name: item.value,
      searchField: item.searchField,
      searchTerm: item.value,
    });

  useEffect(() => {
    const suggestions: Record<
      'instrument' | 'buySide' | 'sellSide',
      SuggestionResponse[]
    > = {
      instrument: [],
      buySide: [],
      sellSide: [],
    };

    const itemsWithGroup: SuggestionBlotter[] = [];

    if (data) {
      data.forEach(item => {
        switch (item.searchFieldId) {
          case EntitySearchFieldsEnum.Instrument:
            suggestions.instrument.push(...item.values);
            break;
          case EntitySearchFieldsEnum.BuyerCompany:
          case EntitySearchFieldsEnum.BuyerContactName:
          case EntitySearchFieldsEnum.BuyerTradingAccount:
            suggestions.buySide.push(...item.values);
            break;
          case EntitySearchFieldsEnum.SellerCompany:
          case EntitySearchFieldsEnum.SellerContactName:
          case EntitySearchFieldsEnum.SellerTradingAccount:
            suggestions.sellSide.push(...item.values);
            break;
        }
      });

      if (suggestions.instrument.length) {
        itemsWithGroup.push({
          group: Section[Section.Instrument],
          items: suggestions.instrument.map(suggestionMapper(Section[Section.Instrument]))
        });
      }

      if (suggestions.buySide.length) {
        itemsWithGroup.push({
          group: Section[Section.Buyer],
          items: suggestions.buySide.map(suggestionMapper(Section[Section.Buyer]))
        })
      }

      if (suggestions.sellSide.length) {
        itemsWithGroup.push({
          group: Section[Section.Seller],
          items: suggestions.sellSide.map(suggestionMapper(Section[Section.Seller]))
        });
      }
    }

    if (checkIfNumberOrRange(debouncedValue) && !isLoading && !isValidating) {
      const range = debouncedValue.split('-');
      const from = Number.parseFloat(range[0]?.trim());
      let to = Number.parseFloat(range[1]?.trim());
      const isFromNumber = !isNaN(from);
      const isToNumber = !isNaN(to);

      // if both are number -> check if 2nd is larger
      // if only 1st is a number -> it's ok for 2nd to be NaN (it's not a range then),
      // but reassign it later, after equality check to avoid inputs like '9-9'
      if ((isFromNumber && isToNumber && to > from) || (isFromNumber && !isToNumber)) {
        to = isToNumber ? to : from; // use `from` as a `to` for number only (not a range) - it's required by BE

        itemsWithGroup.push({
          group: Section[Section.Price],
          items: [{
            group: Section[Section.Price],
            label: debouncedValue,
            name: debouncedValue,
            groupLabel: Section[Section.Price],
            searchField: BlotterEntitySearchFieldsEnum.Price,
            searchTerm: debouncedValue,
            from,
            to,
            external: true
          }]
        });

        itemsWithGroup.push({
          group: Section[Section.Quantity],
          items: [{
            group: Section[Section.Quantity],
            label: debouncedValue,
            name: debouncedValue,
            groupLabel: Section[Section.Quantity],
            searchField: BlotterEntitySearchFieldsEnum.Quantity,
            searchTerm: debouncedValue,
            from,
            to,
            external: true
          }]
        });
      }

    }

    setSuggestions(itemsWithGroup);
    // eslint-disable-next-line
  }, [data, debouncedValue, isLoading, isValidating]);

  const selectedItems: SuggestionItem[] = useMemo(() =>
    searchItems.searchRequestFields?.map(
      field => {
        let group = '';
        switch (field.searchField as EntitySearchFieldsEnum) {
        case EntitySearchFieldsEnum.Instrument:
          group = Section[Section.Instrument];
          break;
        case EntitySearchFieldsEnum.BuyerCompany:
        case EntitySearchFieldsEnum.BuyerContactName:
        case EntitySearchFieldsEnum.BuyerTradingAccount:
          group = Section[Section.Buyer];
          break;
        case EntitySearchFieldsEnum.SellerCompany:
        case EntitySearchFieldsEnum.SellerContactName:
        case EntitySearchFieldsEnum.SellerTradingAccount:
          group = Section[Section.Seller];
          break;
      }

      return {
        ...field,
        group,
        searchField: field.searchField as EntitySearchFieldsEnum,
        name: field.searchTerm,
        label: field.searchTerm,
      };
      }) ?? [], [searchItems.searchRequestFields]);

  const externalItems: SuggestionItemBlottter[] = useMemo(() =>
    [...(searchItems.prices ?? []), ...(searchItems.quantities ?? [])].map(field => {
      let group = '';
      switch (field.searchField as BlotterEntitySearchFieldsEnum) {
        case BlotterEntitySearchFieldsEnum.Price:
          group = Section[Section.Price];
          break;
        case BlotterEntitySearchFieldsEnum.Quantity:
          group = Section[Section.Quantity];
          break;
      }

      return {
        ...field,
        group,
        name: field.searchTerm,
        label: field.searchTerm,
        external: true
      };
    }), [searchItems.prices, searchItems.quantities]);

  return <Autocomplete
    handleInputValueChange={setValue}
    handleSelectedItemsChange={handleSelectedItemsChange}
    isLoading={isLoading || isValidating}
    selectedItems={[...selectedItems, ...externalItems] as SuggestionItem[]}
    suggestions={suggestions as Suggestion[]}
    placeholder='Search by instrument, buy side, sell side, quantity, price'
    autoExpandOnFocus={isMobile}
    useToggle={!isMobile}
    useSections
    containerRef={searchContainerRef}
  />;
};
