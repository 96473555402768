import { UIEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useMediaQuery } from 'react-responsive';
import { DateTime } from 'luxon';
import clsx from 'clsx';

import eventBus from 'server/EventBus';
import { useSignalR } from 'App';
import { useLoadUserSettings, useSaveUserSetting } from 'components/OBXUser/Services/ProfileHooks';
import { WorksheetMutationTypes, useCreateNewWorksheet, useLoadWorksheet, useMutateWorksheet } from 'components/Worksheets/Services/WorksheetHooks';
import { WorksheetSignalMessageEventTypes, WorksheetStores } from 'components/Worksheets/Models/Enums';
import { UISettings } from 'components/OBXUser/Model/Enums';
import ToastMessage, { type ToastMessageRef, ToastSeverity } from 'components/ToastMessage';
import { insertItemAt, removeItemAt, replaceItemAt } from 'helpers/Utils/collections';
import { parsePropsToDateTime } from 'helpers/Utils/misc';

import BlotterDataTable from './Components/BlotterDataTable';
import TradeDetails from './Components/TradeDetails';
import TradeSearch from './Components/TradeSearch';
import LastUpdatedInfo from './Components/LastUpdatedInfo';
import { tradeSearchWorksheetParsers } from './Models/Parsers';
import { BlotterEntitySearchFieldsEnum } from './Models/Enums';
import { BLOTTER_PAGE_SIZE, EMPTY_SEARCH_PARAMS, MOBILE_HEADER_HEIGHT } from './Models/Consts';
import { useGetTrades } from './Services/BlotterAPI';
import { BlotterSignalEventTypes, BlotterSocket } from './Services/SignalRSocket';

import type { VirtualScrollerChangeEvent } from 'primereact/virtualscroller';
import type { TradesDataResponse } from './Models/BlotterResponse';
import type { DateTimeSearchRequest, PriceSearchRequest, QuantitySearchRequest, SearchRequest, SearchRequestFields } from './Models/SearchRequest';
import type { WorksheetResponse, WorksheetMetaProps, WorksheetSearchField } from 'components/Worksheets/Models/WorksheetResponse';
import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';

import './BlotterPage.scss';

export default function BlotterPage(): JSX.Element {

  const { getSetting } = useLoadUserSettings();
  const { trigger } = useSaveUserSetting();
  const { newWorksheet } = useCreateNewWorksheet(WorksheetStores.Blotter);
  const { signal } = useSignalR();

  const [rightPanel, setRightPanel] = useState<boolean>(false);
  const [worksheetId, setWorksheetId] = useState<WorksheetResponse['worksheetId'] | undefined>(getSetting(UISettings.BLOTTER_ACTIVE_WORKSHEET));
  const [selectedTrade, setSelectedTrade] = useState<TradesDataResponse | null>(null);
  const [searchItems, setSearchItems] = useState<SearchRequest>(EMPTY_SEARCH_PARAMS);
  const [trades, setTrades] = useState<TradesDataResponse[]>([]);
  const [lastUpdated, setLastUpdated] = useState<DateTime | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [canConsolidateTrades, setCanConsolidateTrades] = useState<boolean>(true);

  const toast = useRef<ToastMessageRef>(null);
  const headerRef = useRef<HTMLElement>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const mutateSearchItems = useCallback((mutation: Partial<SearchRequest>): void => {
    setSearchItems(c => ({ ...c, ...mutation }));
  }, []);

  // Worksheet data load
  const { data, /* error: isLoadingWorksheetError,*/ isLoading: isLoadingWorksheet } = useLoadWorksheet(
    WorksheetStores.Blotter,
    worksheetId,
    tradeSearchWorksheetParsers
  );

  // Worksheet mutate loader
  const { worksheet, mutateWorksheet, mutateAdditionalProps, mutateTokens, isMutating } = useMutateWorksheet(
    WorksheetStores.Blotter,
    worksheetId
  );

  // Mutate worksheet with data
  useEffect(() => {
    if (!data || data.store !== WorksheetStores.Blotter) {
      return;
    }

    mutateWorksheet({ type: WorksheetMutationTypes.Full, payload: data });

    eventBus.dispatch(
      WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
      data
    );
  }, [data, mutateWorksheet]);

  // Load stored searchItems from worksheet
  useEffect(() => {
    // If there is no worksheet or data has been already restored -> exit
    if (!worksheet || !worksheet.fields) {
      return;
    }

    // parse saved fields to search items
    const props: SearchRequestFields[] = tradeSearchWorksheetParsers.fieldsParser(worksheet.fields);

    // parse additional properties
    const additionals: WorksheetMetaProps[] = tradeSearchWorksheetParsers.propsParser(worksheet.additionalSearchProperties);
    const dateTime = additionals.find(d => d.key === 'dateTime') as { key: string; value: DateTimeSearchRequest; } | undefined;
    const prices = additionals.find(d => d.key === 'prices') as { key: string; value: PriceSearchRequest[]; } | undefined;
    const quantities = additionals.find(d => d.key === 'quantities') as { key: string; value: QuantitySearchRequest[]; } | undefined;
    const lastUpdated = additionals.find(d => d.key === 'lastUpdated') as { key: string; value: string; } | undefined;

    // Mutate searchItems with loaded props (fields) and with Additional Search Props
    mutateSearchItems({
      searchRequestFields: props,
      dateTime: dateTime?.value,
      prices: prices?.value,
      quantities: quantities?.value,
    });
    setLastUpdated(lastUpdated ? DateTime.fromISO(lastUpdated.value, { zone: 'utc' }) : undefined);
  }, [mutateSearchItems, worksheet]);

  // Handle actual search -> request API
  const { data: tradesData, error, isLoading, isValidating } =
    useGetTrades({ ...searchItems, pageNumber, pageSize: BLOTTER_PAGE_SIZE });

  useEffect(() => {
    if (canConsolidateTrades && tradesData?.results && trades.length < pageNumber * BLOTTER_PAGE_SIZE) {
      // add paged items at the end
      setTrades(trades => {
        return [...trades, ...tradesData.results];
      });
      setCanConsolidateTrades(false);
    }
    // eslint-disable-next-line
  }, [tradesData]);

  useEffect(() => {
    // reset
    setTrades([]);
    setPageNumber(1);
    setCanConsolidateTrades(true);
  }, [searchItems])

  useEffect(() => {
    const socket: BlotterSocket = BlotterSocket.instance;
    socket.init(signal);
  }, [signal]);

  const onTradeUpdated = (trade: TradesDataResponse): void => {
    setTrades(trades => {
      const index = trades.findIndex(t => t.id === trade.id);

      if (index === -1) {
        return insertItemAt(trades, 0, parsePropsToDateTime(trade, ['dateTime']));
      }

      return replaceItemAt(trades, parsePropsToDateTime(trade, ['dateTime']), index);
    });
  };

  const onTradeDeleted = (tradeId: string): void => {
    setTrades(trades => {
      const index = trades.findIndex(t => t.id === tradeId);
      if (index !== -1) {
        return removeItemAt(trades, index);
      }
      return trades;
    });
  };

  useEffect(() => {
    const onUpdated = (event: CustomEvent<TradesDataResponse>): void => {
      // updateCacheOnTradeUpdate(event.detail);
      onTradeUpdated(event.detail);
    };

    const onDeleted = (event: CustomEvent<{ id: string; }>): void => {
      // updateCacheOnTradeDelete(event.detail.id);
      onTradeDeleted(event.detail.id);
    };

    eventBus.on(BlotterSignalEventTypes.BLOTTER_TRADE_UPDATED, onUpdated);
    eventBus.on(BlotterSignalEventTypes.BLOTTER_TRADE_DELETED, onDeleted);

    return () => {
      eventBus.remove(BlotterSignalEventTypes.BLOTTER_TRADE_UPDATED, onUpdated);
      eventBus.remove(BlotterSignalEventTypes.BLOTTER_TRADE_DELETED, onDeleted);
    };
  }, []);

  // Handle main search field change
  const handleSearchEntityCallback = (fields: SearchRequestFields[]): void => {
    mutateSearchItems({ searchRequestFields: fields });
    mutateTokens(fields as WorksheetSearchField[]);
    mutateAdditionalProps(stringifyAdditionalProps([
      ...worksheet.additionalSearchProperties.filter(item => item.key !== 'lastUpdated'),
      // always update 'lastUpdated' property
      { key: 'lastUpdated', value: JSON.stringify(DateTime.utc()) }
    ]));
  };

  // Handle main search field change for price and quantity
  const handleExternalItemsChangeCallback = (items: SearchRequestFields[]): void => {
    const prices = items.filter(i => i.searchField === BlotterEntitySearchFieldsEnum.Price);
    const quantities = items.filter(i => i.searchField === BlotterEntitySearchFieldsEnum.Quantity);

    mutateSearchItems({ prices, quantities });
    mutateAdditionalProps(stringifyAdditionalProps([
      // filter out current saved prices and quantities
      ...worksheet.additionalSearchProperties.filter(item => item.key !== 'prices' && item.key !== 'quantities' && item.key !== 'lastUpdated'),
      { key: 'prices', value: JSON.stringify(prices) },
      { key: 'quantities', value: JSON.stringify(quantities) },
      { key: 'lastUpdated', value: JSON.stringify(DateTime.utc()) }
    ]));
  };

  // Handle dateTime field change
  const handleDateTimeChangeCallback = (date: ParssedDateTimeResult | null): void => {
    const value: DateTimeSearchRequest | null = date ? {
      fromDate: date.fromString,
      original: date.original,
      toDate: date.toString
    } : null;

    // if it's the same it no makes sense to mutate anything
    if (value?.original !== searchItems.dateTime?.original) {
      mutateSearchItems({ dateTime: value });
      mutateAdditionalProps(stringifyAdditionalProps([
        // filter out current saved dateTime
        ...worksheet.additionalSearchProperties.filter(item => item.key !== 'dateTime' && item.key !== 'lastUpdated'),
        { key: 'dateTime', value: value },
        { key: 'lastUpdated', value: DateTime.utc() }
      ]));
    }
  };

  // ensure that props have stringified values
  const stringifyAdditionalProps = (mutation: any[]): WorksheetMetaProps[] =>
    mutation.map(item => typeof item.value === 'string' ? item : { ...item, value: JSON.stringify(item.value) });


  const addNewWorksheet = useCallback(async (): Promise<void> => {
    const data = await newWorksheet({
      store: WorksheetStores.Blotter,
      hydrator: (): WorksheetMetaProps[] => [],
      name: 'Blotter Worksheet'
    });

    if (!data || !data.worksheetId) {
      return;
    }

    try {
      trigger({
        setting: UISettings.BLOTTER_ACTIVE_WORKSHEET,
        data: data.worksheetId
      });
    } catch (e) {
      toast.current?.replace({
        title: 'Issue occurred',
        message: 'Issue when storing active worksheet',
        severity: ToastSeverity.WARN
      });
    }
    console.info('Blotter worksheet added:', data.worksheetId);
    setWorksheetId(data.worksheetId);
  }, [newWorksheet, trigger]);

  useEffect(() => {
    if (worksheetId) {
      console.info('Blotter active worksheet:', worksheetId);
    } else {
      console.info('Creating Blotter worksheet');
      addNewWorksheet();
    }
  }, [addNewWorksheet, worksheetId]);

  const onTradeSelected = (trade: TradesDataResponse | null): void => {
    setSelectedTrade(trade);
    setRightPanel(!!trade);
  };

  const handleAddTrade = (): void => {
    setSelectedTrade(null);
    setRightPanel(true);
  };

  const handleClearSearch = (): void => {
    setSearchItems(EMPTY_SEARCH_PARAMS);
    mutateTokens([]);
    mutateAdditionalProps(stringifyAdditionalProps([
      { key: 'lastUpdated', value: JSON.stringify(DateTime.utc()) }
    ]));
  };

  const onLazyLoad = (event: VirtualScrollerChangeEvent): void => {
    setPageNumber(pn => {
      // just early check of the types of vars
      if (typeof tradesData?.totalPages !== 'number' || typeof tradesData?.totalResults !== 'number') {
        return pn;
      };

      if ((pn * BLOTTER_PAGE_SIZE) <= (event.last as number) && pn < tradesData.totalPages && trades.length < tradesData.totalResults) {
        setCanConsolidateTrades(true);
        return pn + 1;
      }

      return pn;
    });
  };

  const LastUpdatedInfoCmp = lastUpdated && <LastUpdatedInfo lastUpdated={lastUpdated} />;

  const onTableScroll = (e: UIEvent<HTMLElement>) => {
    if (headerRef.current) {
      const { scrollTop } = e.target as HTMLElement;

      // scroll to the top to hide header element on table scrolling
      headerRef.current.style.top = scrollTop > 0 ? `-${MOBILE_HEADER_HEIGHT}px` : '0';
      headerRef.current.style.maxHeight = scrollTop > 0 ? '0' : `${MOBILE_HEADER_HEIGHT}px`;
    }
  };

  useEffect(() => {
    // reset styles for desktop
    if (headerRef.current && !isMobile) {
      headerRef.current.style.top = '';
      headerRef.current.style.maxHeight = '';
    }
  }, [isMobile])

  return <>
    {!(isMobile && rightPanel) && <header ref={headerRef} className='blotter__header direction--column'>
      <TradeSearch
        addTrade={handleAddTrade}
        clearSearch={handleClearSearch}
        handleSearchEntityCallback={handleSearchEntityCallback}
        handleDateTimeChangeCallback={handleDateTimeChangeCallback}
        handleExternalItemsChangeCallback={handleExternalItemsChangeCallback}
        searchItems={searchItems}
      />
      {isMobile && LastUpdatedInfoCmp}
    </header>}
    <main
      className={clsx('blotter__main grow-to-fill', {
        'drawer--active': rightPanel
      })}
      data-cols={rightPanel ? '6,6' : '12,0'}
      data-drawer-style='slide'
      data-drawer-position='alongside-right'
    >
      <section className='overflow--hidden'>
        <BlotterDataTable
          trades={trades}
          isLoading={isMutating || isLoadingWorksheet || isLoading || isValidating}
          error={error}
          selectedTrade={selectedTrade}
          onTradeSelected={onTradeSelected}
          onLazyLoad={onLazyLoad}
          onTableScroll={onTableScroll}
        />
      </section>
      {rightPanel && <aside className='blotter__aside position--relative'>
        <TradeDetails
          closePanel={() => onTradeSelected(null)}
          data={selectedTrade}
          toastRef={toast}
          onTradeUpdated={onTradeUpdated}
          onTradeDeleted={onTradeDeleted}
        />
      </aside>}
    </main>
    <footer className={clsx('blotter__footer', {
      'no-background': !isMobile,
    })}>
      {isMobile ?
        (!rightPanel && <Button
          size='small'
          onClick={handleAddTrade}
        >
          Add trade
        </Button>) :
        LastUpdatedInfoCmp
      }
    </footer>
    <ToastMessage ref={toast} />
  </>;
}